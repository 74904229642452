import './style.css';
import {useEffect, useState} from "react";
import fast from '../../assets/truckerFast.jpg'
import privateC from '../../assets/truckerPrivate.png'

function Products(){
    const [opt, setOpt] = useState(0);

    function selectOpt(n){
        setOpt(n)
        let btns = document.querySelectorAll('.top-options button')
        btns.forEach(btn =>
            btn.classList.remove('opt-selected')
        )
        document.querySelectorAll('.top-options button')[n].classList.toggle('opt-selected')
    }

    useEffect(() => {
        document.querySelectorAll('.top-options button')[0].click()
    }, [])

    return(
        <section id='products'>
            <div className="section-container">
                <div className="content-title">
                    <h1>Nossos produtos</h1>
                    <div className="top-options">
                        <button onClick={() => selectOpt(0)}>Fast Cap</button>
                        <button onClick={() => selectOpt(1)}>Private Label</button>
                    </div>
                </div>
                <div className="content-wrapper">
                    {
                        opt === 0 &&
                        <>
                            <div className="wrapper-img">
                                <img src={fast} alt="" />
                            </div>
                            <div className="wrapper-text">
                                <h1>Fast Cap</h1>
                                <p>A linha de produção fast cap é a mais indicada para quem busca rapidez e versatilidade na hora de fazer o pedido, pois nao conta com quantidade mínima e o prazo de entrega é menor. Com diversas cores de bonés já prontos, você envia sua logo e iremos adequa-la para fazer a aplicação com um termocolante sem relevo. Assim como os bonés, o termocolante tem cores limitadas e se necessário realizaremos a alteração para que possamos produzir. Mas tudo é enviado ao cliente de forma que o mesmo analise a arte digital e aprove antes de ir para produção.</p>
                            </div>
                        </>
                    }
                    {
                        opt === 1 &&
                        <>
                            <div className="wrapper-img">
                                <img src={privateC} alt="" />
                            </div>
                            <div className="wrapper-text">
                                <h1>Private Label</h1>
                                <p>Os bonés private label você pode customizar todo o boné com cores e combinações diferentes. O prazo de entrega dessa produção é um pouco maior, pois possui pedido mínimo de 30 unidades e tem um nível de detalhamento maior. Você pode escolher diversas tipos de aplicações de logo, como silk 3D, bordado com e sem relevo, patchs e sublimações.</p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default Products;