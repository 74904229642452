import './style.css';
import Icon from '@mdi/react';
import {mdiEmailCheck, mdiFacebook, mdiInstagram, mdiMapMarker, mdiPhoneClassic, mdiWhatsapp} from '@mdi/js';
import {toast} from "react-toastify";
import {useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import ReactLoading from 'react-loading';

function Contact(){
    const form = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const notify = () => toast.success('Enviado com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const warn = () => toast.error('Preencha todos os campos!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    function send(e){
        e.preventDefault()
        if(name === ''){
            document.querySelector('#name').classList.add('not-right')
            warn()
        }

        if(email === ''){
            document.querySelector('#email').classList.add('not-right')
            warn()
        }

        if(msg === ''){
            document.querySelector('#msg').classList.add('not-right')
            warn()
        }

        if(msg !== '' && email !== '' && msg !== ''){
            document.querySelector('.modal-load').classList.add('modal-load-off')
            document.querySelector('body').style.overflow = 'hidden';
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
                .then((result) => {
                    document.querySelector('.modal-load').classList.remove('modal-load-off')
                    document.querySelector('body').style.overflow = 'unset';
                    notify();
                    document.querySelector('#msg').classList.remove('not-right')
                    document.querySelector('#email').classList.remove('not-right')
                    document.querySelector('#name').classList.remove('not-right')
                }, (error) => {
                    document.querySelector('.modal-load').classList.remove('modal-load-off')
                    document.querySelector('body').style.overflow = 'unset';
                });
        }
    }

    return(
        <section id='contact' style={{padding: '130px 0px', flexDirection: 'column'}}>
            <h1 className="title-contact">Contato</h1>
            <div className="contact-container">
                <div className="wrapper-info">
                    <h3>Entre em contato com a gente</h3>
                    <div className="line-info">
                        <Icon path={mdiMapMarker} />
                        <p>Rua 970, n° 238 - Centro, Balneário Camboriú / SC</p>
                    </div>
                    <div className="line-info">
                        <Icon path={mdiEmailCheck} />
                        <p>personalizados@capsstore.com.br</p>
                    </div>
                    <div className="line-info">
                        <Icon path={mdiWhatsapp} />
                        <p>(47) 99749-6900</p>
                    </div>
                    <div className="line-info">
                        <Icon path={mdiPhoneClassic} />
                        <p>(47) 3514-9728</p>
                    </div>
                    <div className="line-info">
                        <Icon path={mdiInstagram} />
                        <p><a href="https://www.instagram.com/capsstorepersonalizados/" target='_blank'>capsstorepersonalizados</a></p>
                    </div>
                    <div className="line-info">
                        <Icon path={mdiFacebook} />
                        <p><a href="https://www.facebook.com/capsstorebrazil" target='_blank'>capsstorebrazil</a></p>
                    </div>
                </div>
                <div className="wrapper-info">
                    <h3>Deixe sua mensagem</h3>
                    <p>Fique a vontade para nos deixar uma mensagem. Mande sua dúvida ou um pedido de orçamento.</p>
                    <form ref={form} onSubmit={(e) => send(e)}>
                        <input type="text" name="to_name" id="name" placeholder="Seu nome" onChange={(e) => setName(e.target.value)}/>
                        <input type="text" name="from_name" id="email" placeholder="Seu e-mail" onChange={(e) => setEmail(e.target.value)}/>
                        <textarea name="message" id="msg" cols="30" rows="10" placeholder="Deixe sua mensagem" onChange={(e) => setMsg(e.target.value)}></textarea>
                        <input type="submit" value="Enviar"/>
                    </form>
                </div>
            </div>
            <div className="modal-load">
                <ReactLoading type='spin' color="#ffffff" height={'5%'} width={'5%'} />
            </div>
        </section>
    )
}

export default Contact;