import './style.css'
import logo from '../../assets/logocaps.png'
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';

function Header(){

    function showMenu(){
        document.querySelector('.header-container nav').classList.toggle('show-menu')
    }

    return(
        <header>
            <div className="header-container">
                <div className="header-logo">
                    <img src={logo} alt="Logo Caps Store" />
                </div>
                <button className="mobile-btn" onClick={showMenu}>
                    <Icon path={mdiMenu} />
                </button>
                <nav>
                    <ul>
                        <li>
                            <button className="mobile-btn" onClick={showMenu}>
                                <Icon path={mdiMenu} />
                            </button>
                        </li>
                        <li><a href="#top" onClick={() => document.querySelector('.header-container nav').classList.remove('show-menu')}>Início</a></li>
                        <li><a href="#products" onClick={() => document.querySelector('.header-container nav').classList.remove('show-menu')}>Produtos</a></li>
                        <li><a href="#clients" onClick={() => document.querySelector('.header-container nav').classList.remove('show-menu')}>Clientes</a></li>
                        <li><a href="#who-are" onClick={() => document.querySelector('.header-container nav').classList.remove('show-menu')}>Sobre</a></li>
                        <li><a href="#contact" onClick={() => document.querySelector('.header-container nav').classList.remove('show-menu')}>Contato</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;