import './style.css';
import fachada from '../../assets/fachada.jpg'
import clientFoto from '../../assets/clientFoto.png'
function WhoAre(){
    return(
        <section id='who-are'>
            <div className="who-are-container">
                <h1>Quem somos?</h1>
                <div className="wrapper-image-who">
                    <img src={fachada} alt=""/>
                    <p>Há mais de {new Date().getFullYear() - new Date('2015').getFullYear()} anos fabricando bonés, a empresa conta com mais de 1 milhão de unidades produzidas e mais de 10 mil clientes atendidos. A Caps Store trabalha para atender todos os seus clientes da melhor forma possível, proporcionando o melhor atendimento on-line e presencial.</p>
                </div>
                <div className="wrapper-image-who">
                    <p>Nossos clientes regularmente nos procuram para fazer novos pedidos e buscar novas ideias para expor suas marcas ao mundo. Realizamos diversas vendas por todo território Brasileiro e algumas enviadas para o exterior.</p>
                    <img src={clientFoto} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default WhoAre;