import './style.css';
import viseira from '../../assets/viseira.png';
import panama from '../../assets/panama.png';
function Hats(){
    return(
        <section id='hats'>
            <div className="hats-container">
                <h1>Outros produtos</h1>
                <div className="wrapper">
                    <div className="wrapper-hats">
                        <img src={viseira} alt=""/>
                        <h3>Viseira Personalizada</h3>
                        <p>Contamos ainda com a produção de viseiras personalizadas. Aplicamos a sua logo em termocolante e te entregamos no menor tempo possível. As viseiras fazem parte da linha de produção fast cap, você pode ver as cores disponíveis no nosso <a href="https://drive.google.com/file/d/1TAiX7pcvTpG0njwZxHurVUSbPaJN-IFg/view" target="_blank">catálogo fast cap</a></p>
                    </div>
                    <div className="wrapper-hats">
                        <img src={panama} alt=""/>
                        <h3>Chapéu Panamá Personalizado</h3>
                        <p>Feito de palha sintética, costura em nailon, faixa de couro com gravação a laser, de acordo com sua logo. Tamanhos disponíveis: P, M, G e GG.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hats;