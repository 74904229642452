import './index.css'
function Videos(){
    return(
        <section id='videos' style={{backgroundColor: "#2B2B2BFF"}}>
            <div className="videos-container">
                <div className="videos-title">
                    <h1>Uma explicação rápida</h1>
                </div>
                <div className="videos">
                    <div className="video-content">
                        <iframe src="https://www.youtube.com/embed/_kW5M7oy9RM"
                                title="PRODUÇÃO PRIVATE LABEL" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                        </iframe>
                        <h3>Private Label</h3>
                    </div>
                    <div className="video-content">
                        <iframe src="https://www.youtube.com/embed/B-HyCdxzmt0"
                                title="PRODUÇÃO FAST CAP" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                        </iframe>
                        <h3>Fast Cap</h3>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Videos;