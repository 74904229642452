import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './style.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import logo1 from '../../assets/clients/CN.png'
import logo2 from '../../assets/clients/BMW.png'
import logo3 from '../../assets/clients/FG.png'
import logo4 from '../../assets/clients/ANGLO.png'
import logo5 from '../../assets/clients/EQI.png'
import logo6 from '../../assets/clients/ACQUA PLAN.png'
import logo7 from '../../assets/clients/AZIMUT.png'
import logo8 from '../../assets/clients/BOCA MAFRA.png'
import logo9 from '../../assets/clients/DELTA PADEL.png'
import logo10 from '../../assets/clients/DVR.png'
import logo11 from '../../assets/clients/ECODIESEL.png'
import logo12 from '../../assets/clients/EXFLOAT.png'
import logo13 from '../../assets/clients/FOCKER.png'
import logo14 from '../../assets/clients/GRUPO FONTES.png'
import logo15 from '../../assets/clients/IRMÃOS PASSAÚRA.png'
import logo16 from '../../assets/clients/MOBIL MOTOS.png'
import logo17 from '../../assets/clients/ORAL SIN.png'
import logo18 from '../../assets/clients/PASQUALOTTO.png'
import logo19 from '../../assets/clients/R21.png'
import logo20 from '../../assets/clients/RODOVIVA.png'
import logo21 from '../../assets/clients/SHURASTEY.png'
import logo22 from '../../assets/clients/SUMITOMO.png'
import logo23 from '../../assets/clients/TAMANDARÁ VEÍCULOS.png'

function CLients(){
    return(
        <section id='clients' style={{backgroundColor: 'var(--black)'}}>
            <div className="clientes-container">
                <h1>Nossos Clientes</h1>
                <p>Conheça algumas empresas e pessoas que confiaram suas ideias em nosso time de vendas e confecção na hora de colocar a sua marca em evidência nos nossos bonés de qualidade.</p>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                        420: {
                            slidesPerView: 3,
                            spaceBetween: 70,
                        },
                    }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide className='swiper-slide'><img src={logo1} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo2} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo3} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo4} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo5} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo6} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo7} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo8} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo9} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo10} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo11} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo12} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo13} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo14} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo15} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo16} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo17} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo18} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo19} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo20} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo21} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo22} alt=""/></SwiperSlide>
                    <SwiperSlide className='swiper-slide'><img src={logo23} alt=""/></SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}

export default CLients;