import './style.css'
import {mdiFacebook, mdiInstagram, mdiWhatsapp} from '@mdi/js';
import Icon from "@mdi/react";
import logo from '../../assets/logocaps.png';

function Footer(){
    return(
        <footer>
            <div className="footer-container">
                <div className="menu-footer">
                    <h1>Loja</h1>
                    <a href="#top">Início</a>
                    <a href="#products">Produtos</a>
                    <a href="#clients">Clientes</a>
                    <a href="#who-are">Sobre</a>
                    <a href="#contact">Contato</a>
                </div>
                <div className="menu-footer">
                    <h1>Redes Socias</h1>
                    <a href="https://www.instagram.com/capsstorepersonalizados/" target='_blank'><Icon path={mdiInstagram} /></a>
                    <a href="https://www.facebook.com/capsstorebrazil" target='_blank'><Icon path={mdiFacebook} /></a>
                    <a href="https://api.whatsapp.com/send?phone=5547997496900&text=Ol%C3%A1%20vim%20atrav%C3%A9s%20do%20site%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank"><Icon path={mdiWhatsapp} /></a>
                </div>
                <div className="menu-footer">
                    <img src={logo} alt=""/>
                </div>
            </div>
            <p>CAPS STORE COMERCIO DE PRODUTOS PERSONALIZADOS EIRELI CNPJ 32.156.917/0001-76 | Rua 970, n° 238 | Bairro Centro | CEP 88330-588 | Balneário Camboriú | SC | Brasil</p>
            <p>© Caps Store Brazil - {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer;