import './App.css';
import Header from "./templates/header";
import TopBg from "./sections/top-bg";
import Products from "./sections/products";
import Videos from "./sections/videos";
import Localization from "./sections/localization";
import CLients from "./sections/clients";
import WhoAre from "./sections/who-are";
import Contact from "./sections/contact";
import Hats from "./sections/hats";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./templates/footer";

function App() {
  return (
    <div className="App">
        <Header />
        <TopBg/>
        <Products />
        <Videos />
        <Hats />
        <CLients />
        <WhoAre />
        <Localization />
        <Contact />
        <ToastContainer />
        <Footer />
    </div>
  );
}

export default App;
