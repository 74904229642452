import './style.css';

function Localization() {
    return (
        <section id='localization' style={{backgroundColor: '#2b2b2b'}}>
            <div className="localization-container">
                <div className="wrapper-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28440.65643588349!2d-48.643216!3d-26.995954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8b5ac9b61978b%3A0x3c4893c619936ffe!2sCaps%20Store%20-%20Bon%C3%A9s%20Personalizados!5e0!3m2!1spt-BR!2sbr!4v1688599730398!5m2!1spt-BR!2sbr"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
                <div className="wrapper-localization">
                    <h1>Onde nos encontrar?</h1>
                    <p>Nossa sede se encontra na cidade de Balneário Camboriú no estado de Santa Catarina. Estamos na rua 970, n° 238 centro.</p>
                </div>
            </div>
        </section>
    );
}

export default Localization;